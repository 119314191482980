/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-lightning-rain-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.658 11.026a.5.5 0 01.316.632l-.5 1.5a.5.5 0 11-.948-.316l.5-1.5a.5.5 0 01.632-.316m9.5 0a.5.5 0 01.316.632l-.5 1.5a.5.5 0 01-.948-.316l.5-1.5a.5.5 0 01.632-.316m-7.5 1.5a.5.5 0 01.316.632l-.5 1.5a.5.5 0 11-.948-.316l.5-1.5a.5.5 0 01.632-.316m9.5 0a.5.5 0 01.316.632l-.5 1.5a.5.5 0 01-.948-.316l.5-1.5a.5.5 0 01.632-.316m-7.105-1.25A.5.5 0 017.5 11h1a.5.5 0 01.474.658l-.28.842H9.5a.5.5 0 01.39.812l-2 2.5a.5.5 0 01-.875-.433L7.36 14H6.5a.5.5 0 01-.447-.724zm6.352-7.249a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 10H13a3 3 0 00.405-5.973"/>',
    },
});
